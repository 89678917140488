import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper, Grid, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Welcome from './pages/Welcome';
import { request } from '../../api/client';
import { init } from '../../actions/user-action';
import { useSlug, useT } from '../austin/hooks';
import LongBeachNextBar from "./LongBeachNextBar";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
});

function LongBeachWelcome(props) {
  const { classes, nextUrl} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const slug = useSlug();
  const [recaptcha, setRecaptcha] = useState(null);
  const [isCertified, setCertified] = useState(false);
  const [error, setError] = useState('');
  const t = useT();
  useEffect(() => {
    window.verifyCallback = (response) => {
      setRecaptcha(response);
      setError('');
    };
  }, []);
  const onCertify = useCallback((evt) => {
    setCertified(evt.target.checked);
  }, [setCertified]);
  const submitVerification = useCallback(
    () => {
      if (recaptcha) {
        request('/api/verify', 'POST', { token: recaptcha, slug }).then((response) => {
          if (!response.ok) {
            setError('error-verify');
          } else {
            response.json().then((body) => {
              dispatch(init({ code: body.code}));
              history.push(nextUrl);
            });
          }
        });
      }
    },
    [recaptcha, setError, dispatch, history, nextUrl, slug],
  );
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Welcome />
          <div className="g-recaptcha" data-sitekey="6LdvT9MUAAAAAGtAemZapfV8z35lpc33aXxnziSL" data-callback="verifyCallback" />
          <FormControlLabel
            value={isCertified}
            control={<Checkbox color="secondary" checked={isCertified} onChange={onCertify} />}
            label={t('longBeach.certification')}
            labelPlacement="end"
          />
        </Grid>
        <LongBeachNextBar error={error} disabled={recaptcha == null || !isCertified} disabledPrompt="verify-prompt" onClick={submitVerification} />
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(LongBeachWelcome);
